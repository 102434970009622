import './App.css';
import { Container, Typography } from '@mui/material';

function App() {
  return (
    <Container className="App">
      <header className="App-header">
        <Typography variant="h1">Co-Create Sample App</Typography>
        <Typography variant="body1">Welcome to the modernized application!</Typography>
      </header>
    </Container>
  );
}

export default App;